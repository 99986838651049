import { compact } from 'lodash';
import { GetStaticPaths, GetStaticProps } from 'next';
import type { ReactElement } from 'react';

import { ContentBlocks } from '@/components/content-blocks';
import { getAllModels, getPageBySlug } from '@/lib/datocms/api';
import { Layout } from '@/components/layout';
import { EXCLUDED_ROUTES, PAGE_REVALIDATE_INTERVAL, STATIC_PATH_LIMIT } from '@/lib/constants';
import { PageBanner } from '@/components/page-banner';
import { PageHeader } from '@/components/page-header';
import { PagePaths, Page as PageType } from '@/lib/types';
import { getModelURL } from '@/lib/datocms/utils/get-model-url';

type Params = { slug: string[] };
type PageProps = { page: PageType; paths: PagePaths };

const FIRST_FORWARD_SLASH_REGEX = /^\//;

export const getStaticPaths: GetStaticPaths = async () => {
  const { pages } = await getAllModels();
  const paths = pages.map((page) => ({ url: getModelURL(page) }));
  return {
    paths: compact(
      paths.slice(0, STATIC_PATH_LIMIT).map(
        ({ url }) =>
          !EXCLUDED_ROUTES.includes(url) && {
            params: { slug: url.replace(FIRST_FORWARD_SLASH_REGEX, '').split('/') },
          }
      )
    ),
    fallback: 'blocking',
  };
};

export const getStaticProps: GetStaticProps<PageProps, Params> = async ({ params = { slug: [] } }) => {
  const data = await getPageBySlug({ slug: params.slug });

  if (!data || !data.page) {
    return {
      notFound: true,
    };
  }

  const { page, paths } = data;

  return {
    props: { page, paths },
    revalidate: PAGE_REVALIDATE_INTERVAL,
  };
};

const Page = ({ page, paths }: PageProps): ReactElement => {
  return (
    <Layout metaTags={page.metaTags} paths={paths}>
      <div className="component content ract-product">
        <div className="component-content">
          <PageBanner heroImage={page.heroImage} />
          <article className="product-wrapper">
            <PageHeader page={page} />
            <ContentBlocks blocks={page.content} />
          </article>
        </div>
      </div>
    </Layout>
  );
};

export default Page;
