import { isEmpty, kebabCase } from 'lodash';
import clsx from 'clsx';
import type { ReactElement } from 'react';

import { CtaLink, Page as PageType } from '@/lib/types';
import { getModelURL } from '@/lib/datocms/utils/get-model-url';
import { Icon } from '@/components/icon';
import { Link } from '@/components/link';
import { HUNDRED_YEAR_TAKEOVER } from '@/lib/constants';

const Features = ({ features }: { features: string[] }): ReactElement | null =>
  isEmpty(features) ? null : (
    <ul>
      {features.map((feature) => (
        <li key={kebabCase(feature)}>
          <Icon
            className={clsx('mr-2', HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined)}
            name="check-circle"
          />
          {feature}
        </li>
      ))}
    </ul>
  );

const CtaButtons = ({ ctaButtons }: { ctaButtons: CtaLink[] }): ReactElement | null =>
  isEmpty(ctaButtons) ? null : (
    <div className="mt-4 d-sm-flex">
      {ctaButtons.map(({ id, link, linkText, anchor }, index) => {
        const isPrimaryButton = index === 0;

        return (
          <Link
            key={id}
            className={clsx(
              'btn d-block',
              `btn-${isPrimaryButton ? 'white mr-sm-4 mb-3 mb-sm-0' : 'outline-white'}`,
              HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined
            )}
            href={getModelURL(link, anchor)}
            analytics={{
              context: isPrimaryButton ? 'CTA - Primary CTA' : 'CTA - Secondary CTA',
              text: linkText,
            }}
          >
            {linkText}
          </Link>
        );
      })}
    </div>
  );

const ContentLinks = ({ contentLinks }: { contentLinks: CtaLink[] }): ReactElement | null =>
  isEmpty(contentLinks) ? null : (
    <section className="product-links">
      <div className={clsx('product-link-wrapper', HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined)}>
        {contentLinks.map(({ id, link, linkText, anchor }) => (
          <div key={id} className="product-link-group">
            <Icon className="text-white fa-xs mr-1" name="chevron-right" />
            <Link
              href={getModelURL(link, anchor)}
              analytics={{
                context: 'Secondary Link',
                text: linkText,
              }}
            >
              {linkText}
            </Link>
          </div>
        ))}
      </div>
    </section>
  );

const PageHeader = ({ page }: { page: PageType }): ReactElement | null => {
  const showHeader = [page.subtitle, page.features, page.ctaButtons, page.contentLinks].some((item) => !isEmpty(item));
  const hasHeroImage = !isEmpty(page.heroImage);

  if (!showHeader) {
    return null;
  }

  // Split features string into an array of lines by new line characters
  // Implementation from: https://github.com/sindresorhus/split-lines
  const features = !isEmpty(page.features) ? page.features.split(/\r?\n/) : [];

  return (
    <div className={clsx('product-container container col-12', { 'has-hero-image': hasHeroImage })}>
      <section className={clsx('field-wrapper', HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : undefined)}>
        <h1 className="field-title text-white mb-3">{page.title}</h1>
        <div className="product-lead">
          {page.subtitle && <p className="text-light-blue mb-0">{page.subtitle}</p>}
          <Features features={features} />
          <CtaButtons ctaButtons={page.ctaButtons} />
        </div>
      </section>
      <ContentLinks contentLinks={page.contentLinks} />
    </div>
  );
};

export { PageHeader };
