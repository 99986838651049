import { FC } from 'react';

import { Image } from '@/components/image';
import { ImageType } from '@/lib/types';

type PageBannerProps = {
  children?: React.ReactNode;
  heroImage?: null | ImageType;
};

const PageBanner: FC<PageBannerProps> = ({ heroImage = null, children = null }) => {
  if (!heroImage) {
    return null;
  }

  return (
    <div className="col-12">
      <div className="photo-bg page-banner">
        <Image {...heroImage} isBackground lazyLoad={false} />
        {children}
      </div>
    </div>
  );
};

export { PageBanner };
